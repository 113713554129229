<template>
    <div>
        <!-- <div v-if="productDTOS">
            <div class="block-title">
                历史版本
            </div>
        </div> -->
        <el-tabs class="tab">
            <el-tab-pane label="2024b" class="custom-tab">
                <div v-for="(item, index) of products" :key="index" class="body"
                    :style="productDTOS ? { maxWidth: '1180px' } : { maxWidth: '900px' }">
                    <div class="icon" v-if="item.icon">
                        <img :src="item.icon" />
                    </div>
                    <div class="text">
                        <div class="title">
                            <a :href="item.releaseNoteViewRouter" style="color: #4577bb">{{ item.name }}</a>
                        </div>
                        <div class="info">
                            <div>{{ item.label }}</div>
                            <div class="describe">
                                <div v-if="item.runtimeEnv" style="margin-right: 5px;">{{ item.runtimeEnv }}</div>
                                <div v-if="item.updateTime && item.downloadFlag">更新日期：{{ item.updateTime.substr(0, 10)
                                    }}</div>
                            </div>
                            <a href="/help/SyslabHelp.html?release=Release_Notes_V0.11.4"
                                v-if="item.name.includes('MWORKS.Syslab 2024b')" class="release"
                                target="_blank">查看发行说明</a>
                            <a href="/help/SysplorerHelp.html?release=Release_Notes_V6.0.6"
                                v-if="item.name.includes('MWORKS.Sysplorer 2024b')" class="release"
                                target="_blank">查看发行说明</a>
                        </div>
                    </div>
                    <div>
                        <el-dropdown trigger="click" split-button v-if="item.downloadFlag && item.downloadList.length"
                            style="margin-top: 2px;">
                            <!-- <a style="color: white;"
                                :href="item.downloadList[0].url ? '/official/product/download?path=' + item.downloadList[0].url : null"> -->
                            <a style="color: white;" :href="item.downloadList[0].url ? item.downloadList[0].url : null"
                                @click="handleDownload(item.downloadList[0])">
                                <div style="height: 50%;padding-top: 10%;">立即下载</div>
                                <div style="height: 50%;">{{ item.downloadList[0].systemName }}</div>
                            </a>
                            <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                <div style="color: darkgray;display: flex;padding: 0 20px;">
                                    <div style="width: 150px;"></div>
                                    <div style="width: 75px;text-align: center;">直接下载</div>
                                    <div style="width: 55px;text-align: center;">百度网盘</div>
                                </div>
                                <el-dropdown-item v-for="(button, index) of item.downloadList" :key="index">
                                    <div style="display: flex;">
                                        <div class="system-name" :title="button.systemName">{{ button.systemName }}
                                        </div>
                                        <div class="system-bit">{{ button.systemBit }}</div>
                                        <a :href="button.url ? button.url : null"
                                            style="width: 75px;text-align: center;" @click="handleDownload(button)">
                                            <i class="el-icon-download"
                                                style="color: #497ddc;font-size: 18px;font-weight: bold;"></i>
                                        </a>
                                        <a :href="button.baiduNetdisk" style="width: 55px;text-align: center;"
                                            @click="handleDownload(button)">
                                            <i class="el-icon-download" style="font-size: 18px;font-weight: bold;"></i>
                                        </a>
                                    </div>
                                    <el-divider v-if="index + 1 < item.downloadList.length"></el-divider>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <a v-else-if="item.buttonName" :href="item.buttonRouter" style="color: white;">
                            <el-button style="width: 150px;">
                                <div class="button">{{ item.buttonName }}</div>
                            </el-button>
                        </a>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="历史版本" class="custom-tab">
                <div v-for="(item, index) of historyProducts" :key="index" class="body"
                    :style="productDTOS ? { maxWidth: '1180px' } : { maxWidth: '900px' }">
                    <div class="icon" v-if="item.icon">
                        <img :src="item.icon" height="80px" width="80px" />
                    </div>
                    <div class="text">
                        <div class="title">
                            <a :href="item.releaseNoteViewRouter" style="color: #4577bb">{{ item.name }}</a>
                        </div>
                        <div class="info">
                            <div>{{ item.label }}</div>
                            <div class="describe">
                                <div v-if="item.runtimeEnv" style="margin-right: 5px;">{{ item.runtimeEnv }}</div>
                                <div v-if="item.updateTime && item.downloadFlag">更新日期：{{ item.updateTime.substr(0, 10)
                                    }}</div>
                            </div>
                            <a href="/help/SyslabHelp.html?release=Release_Notes_V0.11.2"
                                v-if="item.name === 'MWORKS.Syslab 2024a SP1'" class="release"
                                target="_blank">查看发行说明</a>
                            <a href="/help/SysplorerHelp.html#/Doc/WhatsNew/Release_Notes_V6.0.3.html"
                                v-if="item.name === 'MWORKS.Sysplorer 2024a SP1'" class="release"
                                target="_blank">查看发行说明</a>
                            <a href="/help/SysplorerHelp.html#/Doc/WhatsNew/Release_Notes_V6.0.2.html"
                                v-if="item.name === 'MWORKS.Sysplorer 2024a'" class="release" target="_blank">查看发行说明</a>
                            <a href="/help/SyslabHelp.html?release=Release_Notes_V0.11.1"
                                v-if="item.name === 'MWORKS.Syslab 2024a'" class="release" target="_blank">查看发行说明</a>
                            <a href="/help/SysplorerHelp.html#/Doc/WhatsNew/Release_Notes_V5.3.4.html"
                                v-if="item.name === 'MWORKS.Sysplorer 2023b'" class="release" target="_blank">查看发行说明</a>
                            <a href="/help/SyslabHelp.html?release=Release_Notes_V0.10.1"
                                v-if="item.name === 'MWORKS.Syslab 2023b'" class="release" target="_blank">查看发行说明</a>
                            <a href="/help/SysplorerHelp.html#/Doc/WhatsNew/Release_Notes_V5.2.1.html"
                                v-if="item.name === 'MWORKS.Sysplorer 2023a'" class="release" target="_blank">查看发行说明</a>
                        </div>
                    </div>
                    <div>
                        <el-dropdown trigger="click" split-button v-if="item.downloadFlag && item.downloadList.length"
                            style="margin-top: 2px;">
                            <!-- <a style="color: white;"
                                    :href="item.downloadList[0].url ? '/official/product/download?path=' + item.downloadList[0].url : null"> -->
                            <a style="color: white;" :href="item.downloadList[0].url ? item.downloadList[0].url : null"
                                @click="handleDownload(item.downloadList[0])">
                                <div style="height: 50%;padding-top: 10%;">立即下载</div>
                                <div style="height: 50%;">{{ item.downloadList[0].systemName }}</div>
                            </a>
                            <el-dropdown-menu slot="dropdown" :append-to-body="false">
                                <div style="color: darkgray;display: flex;padding: 0 20px;">
                                    <div style="width: 150px;"></div>
                                    <div style="width: 75px;text-align: center;">直接下载</div>
                                    <div style="width: 55px;text-align: center;">百度网盘</div>
                                </div>
                                <el-dropdown-item v-for="(button, index) of item.downloadList" :key="index">
                                    <div style="display: flex;">
                                        <div class="system-name" :title="button.systemName">{{ button.systemName }}
                                        </div>
                                        <div class="system-bit">{{ button.systemBit }}</div>
                                        <a :href="button.url ? button.url : null"
                                            style="width: 75px;text-align: center;" @click="handleDownload(button)">
                                            <i class="el-icon-download"
                                                style="color: #497ddc;font-size: 18px;font-weight: bold;"></i>
                                        </a>
                                        <a :href="button.baiduNetdisk" style="width: 55px;text-align: center;"
                                            @click="handleDownload(button)">
                                            <i class="el-icon-download" style="font-size: 18px;font-weight: bold;"></i>
                                        </a>
                                    </div>
                                    <el-divider v-if="index + 1 < item.downloadList.length"></el-divider>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <a v-else-if="item.buttonName" :href="item.buttonRouter" style="color: white;">
                            <el-button style="width: 150px;">
                                <div class="button">{{ item.buttonName }}</div>
                            </el-button>
                        </a>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
  props: {
    productDTOS: Array
  },
  data() {
    return {
      products: [],
      historyProducts: [],
      userInfo: null
    };
  },
  created() {
    this.userInfo = this.$utils.getStorage('userInfo');
    if (this.productDTOS) {
      this.products = this.productDTOS;
    } else {
      this.getProducts();
      this.getHistoryProducts();
    }
  },
  methods: {
    async getProducts() {
      const res = await this.$api.getProducts();
      if (res.code == 200) {
        this.products = res.data;
        console.log('products', this.products);
      }
    },
    async getHistoryProducts() {
      const res = await this.$api.getHistoryProducts();
      if (res.code == 200) {
        this.historyProducts = res.data;
        console.log('historyProducts', this.historyProducts);
      }
    },
    toUrl(url) {
      this.$router.push(url);
    },
    async handleDownload(button) {
      const currentDate = new Date();
      const formattedTime = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;
      let obj = {};
      if (this.userInfo) {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          },
          userName: this.userInfo.sub
        };
      } else {
        obj = {
          downloadId: button.id,
          event: 'download',
          eventTime: formattedTime,
          message: {
            downloadId: button.id,
            productId: button.productId
          }
        };
      }
      console.log('obj111', obj);
      const res = await this.$api.addEventMessage(obj);
      if (res.code == 200) {
        console.log(res + '成功');
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.release{
    border: 1px solid #4577bb;
    color:#4577bb;
    border-radius: 4px;
    padding: 2px 5px;
}
.block-title {
    display: block;
    max-width: 1180px;
    font-size: 24px;
    line-height: 30px;
    margin: 0px auto;
}

/deep/ .el-dropdown-menu__item i {
    margin-right: 0;
}

/deep/ .el-tabs {
    // width: 50%;
    /* 设置 el-tabs 宽度为100% */
    margin: 0 auto;
}

/deep/ .el-tabs .el-tabs__content{
    padding-bottom: 200px;
}
/deep/.el-tabs .el-tabs__item{
    font-size: 26px;
    font-weight: 400;
    // color: #626060;
    padding-bottom: 5px;
    margin-top: 15px;
    margin-bottom: 20px;
}
/deep/.el-tabs--top {
        .el-tabs__item.is-top:nth-child(2) {
            // color: #ff8c00;
            font-weight: 500;
        }
        .el-tabs__item.is-top:nth-child(3) {
            font-size: 22px;
        }
}

/deep/.tab .el-tabs__nav-scroll{
    display: flex;
    justify-content: center;
    // width:100%;
    // margin:0 auto
}
/deep/.tab .el-tabs__nav{
    font-size: 36px;
}

/deep/.tab .el-tabs__nav-wrap::after{
    content: none;
    // width: 80%;
    // margin: 0 auto;
}

// /deep/.tab .el-tabs .el-tabs__nav-wrap::after {
//     content: none;
// }

.body {
    margin: 20px auto  40px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.icon {
    text-align: center;
}
.icon img{
    height: 100px;
    width: 100px;
    margin-right: 10px;
}

.system-name {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    width: 100px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
}
.system-bit {
    font-size: 16px;
    width: 50px;
    text-align: center;
}

.text {
    flex: 1;
    padding-right: 50px;
    justify-content: unset;
    display: flex;
    flex-direction: column;
}

.title {
    width: 100%;
    color: rgba(32, 32, 32, 1);
    font-size: 22px;
    font-family: PingFangSC-Regular;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
    margin: 3px auto 0 50px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.info {
    width: 100%;
    color: rgba(138, 138, 139, 1);
    font-size: 14px;
    font-family: PingFangSC-Regular;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
    margin: 4px auto 0 50px;

    .describe {
        display: flex;
    }
}

.button {
    height: 100%;
    padding-top: 15%;
}

/deep/ .el-dropdown .el-dropdown__caret-button {
    width: 30px;
}

/deep/ .el-button--default {
    width: 120px;
    height: 60px;
    background-color: #497ddc;
    border-radius: 6px;
    padding: 0;
    font-size: 15px;
    color: white;
    font-weight: 500;

    &:hover {
        background-color: #5e8bde;
    }
}

/deep/ .el-dropdown .el-button-group {
    display: flex;
}

/deep/ .el-dropdown-menu {
    transform: translateX(0);
    z-index: 1990 !important;
    width: fit-content;
}

/deep/ .el-popper .popper__arrow{
    border: none;
}

/deep/ .el-popper .popper__arrow::after{
    border: none;
}

/deep/ .el-popper {
    margin-top: 8px;
}

/deep/ .el-divider--horizontal {
    margin: 2px 0;
}

@media screen and (max-width: 750px) {
    .text {
        padding-right: 0;
        padding-left: 10px;
        overflow: hidden;
    }

    .title {
        font-size: 13px;
        margin: 0 auto 0 5px;
        line-height: 18px;
    }

    .info {
        line-height: 18px;
        font-size: 10px;
        margin: 5px auto 0 5px;

        .describe {
            display: block;
        }
    }

    .button {
        padding-top: 12%;
    }

    .icon {
        width: 60px;
    }

    .icon img {
        height: 60px;
        width: 60px;
    }

    /deep/ .el-button--default {
        height: 50px;
        font-size: 13px;
    }
}

@media screen and (max-width: 700px) {
    .block-title {
        text-align: center;
    }

    /deep/ .el-dropdown-menu {
        position: absolute !important;
        top: 45px !important;
        // right: 0 !important;
        left: -171px !important;
    }
}

@media screen and (max-width: 430px) {
    .icon {
        display: none;
    }
}
</style>
